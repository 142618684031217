import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    author: null,
    avatarPath: null,
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    author: state => state.author,
    busy: state => state.busy,
    avatarPath: state => state.avatarPath
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setAuthor(state, response) {
        if (response == null) {
            state.author = null;
        } else {
            state.author = response.data;
            state.avatarPath = response.data.avatarPath
        }
    },
    setAvatarPath(state, avatarPath) {
        state.avatarPath = avatarPath;
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/author/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getAuthor({commit}, data) {
        axios.get(`/author/${data}/details`)
            .then(function (response) {
                commit('setAuthor', response);
            })
    },
    changeAvatarPath (context, avatarPath) {
        context.commit('setAvatarPath', avatarPath)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
