<template>
  <div class="wrapper">
    <nav v-if="user" class="main-header navbar navbar-expand navbar-dark navbar-info">
      <ul class="navbar-nav">
        <li class="nav-item"><a data-widget="pushmenu" @click="collapseMenu()" class="nav-link"><i
            class="fas fa-bars"></i></a></li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown" :class="{'show': dropdown}">
          <a id="dropdownSubMenu1" @click="dropdown = !dropdown" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false" class="nav-link dropdown-toggle">{{ user.login }}</a>
          <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu dropdown-menu-right border-0 shadow"
              :class="{'show': dropdown}">
<!--            <li>-->
<!--              <router-link class="dropdown-item" :to="{ name: 'administrator-profile', params: { id: user.userIdentifier }}">-->
<!--                <b-icon-people class="mr-2"></b-icon-people> Profile-->
<!--              </router-link>-->
<!--            </li>-->
            <li>
              <a @click="logout()" class="dropdown-item">
                <b-icon icon="toggle-off" class="mr-2" aria-hidden="true"></b-icon> Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <aside class="main-sidebar sidebar-dark-blue elevation-4">
      <a href="/vue" class="brand-link bg-info text-center">
        <span class="brand-text font-weight-bold pt-3 pb-3">
          Somusic
        </span>
      </a>
      <div class="sidebar">
        <nav class="mt-2">
          <ul data-widget="treeview" role="menu" data-accordion="false"
              class="nav nav-pills nav-sidebar nav-child-indent flex-column">
            <li class="nav-item first">
              <router-link
                  to="/"
                  class="nav-link"
              >
                <b-icon icon="clipboard-data" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.dashboard') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/clients"
                  class="nav-link"
              >
                <b-icon icon="people" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.clients') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/authors"
                  class="nav-link"
              >
                <b-icon icon="people-fill" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.authors') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/scores"
                  class="nav-link"
              >
                <b-icon icon="music-note" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.scores') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/master-classes"
                  class="nav-link"
              >
                <b-icon icon="list-stars" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.masterClasses') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/subscription-offers"
                  class="nav-link"
              >
                <b-icon icon="view-stacked" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.subscriptionOffer') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/rates"
                  class="nav-link"
              >
                <b-icon icon="star-half" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.rates') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/rental"
                  class="nav-link"
              >
                <b-icon icon="bag" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.rental') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/payment"
                  class="nav-link"
              >
                <b-icon icon="cash" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.payment') }}</p>
              </router-link>
            </li>
            <li class="nav-item first">
              <router-link to="/cms" class="nav-link">
                <b-icon icon="file-text-fill" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.cms') }}</p>
              </router-link>
            </li>
            <li class="nav-item first">
              <router-link to="/statistics?page=lessonWatched" class="nav-link">
                <b-icon icon="bar-chart" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.statistics') }}</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
    <div class="content-wrapper">
      <div class="content">
        <div class="container-fluid">
          <router-view/>
        </div>
      </div>
    </div>
    <footer class="main-footer">
      <strong>Copyright © 2023 Sygnisoft</strong>
    </footer>
    <aside class="control-sidebar control-sidebar-dark advanced-filters"></aside>
    <div id="sidebar-overlay"></div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      dashboard: false,
      dropdown: false
    }
  },
  mounted() {
    if (!this.user) {
      this.$store.dispatch('root/fetchUser');
    }
    this.checkRoute(this.$route);
  },
  computed: {
    user: function () {
      return this.$store.getters["root/user"];
    }
  },
  methods: {
    collapseMenu() {
      const body = document.querySelector('body');
      body.classList.toggle('sidebar-collapse')
    },
    closeEvent: function () {
      this.dropdown = false;
    },
    checkRoute(route) {
      this.closeAll();
      switch (route.name) {
        case 'dashboard': {
          this.dashboard = true;
          break;
        }
      }
    },
    closeAll() {
      this.dashboard = false;
    },
    logout() {
      this.$store.dispatch('root/logout').then(() => {
        this.$router.push({name: 'login'})
      })
    }
  },
  watch: {
    $route() {
      this.checkRoute(this.$route);
    }
  }
}
</script>
