const SubscriptionOfferDetails = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/SubscriptionOfferDetails.vue');
const SubscriptionOfferList = () => import(/* webpackChunkName: "PlayerList" */ './containers/SubscriptionOfferGrid.vue');


export const subscriptionOfferRouting = [
    {path: '/subscription-offers', name: 'subscription-offers-list', component: SubscriptionOfferList},
    {
        path: '/subscription-offer/:id', component: SubscriptionOfferDetails, name: 'subscription-offer-details'
    },
]
