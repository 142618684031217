import axios from "axios";
// import {loadLanguageAsync} from "@/main";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;


const state = () => ({
    apiKey: null,
    loading: false,
    user: null,
    filterStore: {
        clients: null,
        authors: null,
        scores: null
    },
})

// getters
const getters = {
    apiKey: state => state.apiKey,
    loading: state => state.loading,
    user: state => state.user,
    filter: state => name => {
        return state.filterStore[name];
    },
}

// actions
const actions = {
    fetchUser({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`/admin-user`)
                .then(resp => {
                    commit('setUser', resp.data);
                    // localStorage.setItem('defaultLocale', resp.data.profile.locale);
                    // loadLanguageAsync(resp.data.profile.locale, false);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    login({commit, dispatch}, payload) {
        commit('setLoader', true);
        return new Promise((resolve, reject) => {
            axios.post(`/admin-user/login`, payload)
                .then(resp => {
                    commit('setApiKey', resp.data.authToken);
                    localStorage.setItem('apiKey', resp.data.authToken);
                    dispatch('fetchUser').then(
                        () => {
                            commit('setLoader', false);
                            resolve(resp.data);
                        }
                    )
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    // eslint-disable-next-line no-unused-vars
    logout({commit, dispatch}, payload) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`/admin-user/logout`)
                .then(resp => {
                    localStorage.removeItem('apiKey');
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
}

// mutations
const mutations = {
    setApiKey(state, data) {
        state.apiKey = data;
        axios.defaults.headers.common['X-AUTH-TOKEN'] = data;
    },
    setUser(state, data) {
        state.user = data;
    },
    setLoader(state, data) {
        state.loading = data;
    },
    setFilter(state, data) {
        if (data.operator) {
            state.filterStore[data.storeName][data.filterName] = data;
        } else {
            state.filterStore[data.storeName][data.filterName] = data.value;
        }
    },
    initFilters(state, data) {
        state.filterStore[data.storeName] = {};
        data.filterNames.forEach(s => {
            state.filterStore[data.storeName][s] = null;
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
