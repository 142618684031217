const ScoreDetails = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/ScoreDetails.vue');
const ScoreNew = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/ScoreNew.vue');
const ScoreList = () => import(/* webpackChunkName: "PlayerList" */ './containers/ScoreGrid.vue');


export const scoresRouting = [
    {path: '/scores', name: 'scores-list', component: ScoreList},
    {
        path: '/score/:id', component: ScoreDetails, name: 'score-details'
    },
    {
        path: '/add-score', component: ScoreNew, name: 'score-new'
    },
]
