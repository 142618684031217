import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    client: null,
    invoiceData: null,
    subscription: null,
    companyTypes: [],
    invoiceDataTypes: [],
    history: []
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    client: state => state.client,
    invoiceData: state => state.invoiceData,
    subscription: state => state.subscription,
    companyTypes: state => state.companyTypes,
    invoiceDataTypes: state => state.invoiceDataTypes,
    history: state => state.history,
    busy: state => state.busy
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setClient(state, response) {
        if (response == null) {
            state.client = null;
        } else {
            state.client = response.data;
        }
    },
    setCompanyTypes(state, response) {
        if (response == null) {
            state.companyTypes = null;
        } else {
            state.companyTypes = response.data;
        }
    },
    setInvoiceData(state, response) {
        if (response == null) {
            state.invoiceData = null;
        } else {
            state.invoiceData = response.data;
        }
    },
    setSubscription(state, response) {
        if (response == null) {
            state.subscription = null;
        } else {
            state.subscription = response.data;
        }
    },
    setInvoiceDataTypes(state, response) {
        if (response == null) {
            state.invoiceDataTypes = null;
        } else {
            state.invoiceDataTypes = response.data;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/client/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getHistory({commit}, data) {
        axios.get(`/client/${data.clientId}/history?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getFavorite({commit}, data) {
        axios.get(`/client/${data.clientId}/favorite?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getRental({commit}, data) {
        axios.get(`/client/${data.clientId}/rental?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getPayments({commit}, data) {
        axios.get(`/client/${data.clientId}/payments?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getRates({commit}, data) {
        axios.get(`/client/${data.clientId}/rates?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getClient({commit}, data) {
        axios.get(`/client/${data}/details`)
            .then(function (response) {
                commit('setClient', response);
            })
    },
    getCompanyTypes({commit}) {
        axios.get(`/client/company-types`)
            .then(function (response) {
                commit('setCompanyTypes', response);
            })
    },
    getInvoiceDataTypes({commit}) {
        axios.get(`/client/invoice-data-types`)
            .then(function (response) {
                commit('setInvoiceDataTypes', response);
            })
    },
    getClientInvoiceData({commit}, data) {
        axios.get(`/client/${data}/invoice-data`)
            .then(function (response) {
                commit('setInvoiceData', response);
            })
    },
    getSubscriptionDetails({commit}, data) {
        axios.get(`/subscription/client/${data}/current`)
            .then(function (response) {
                commit('setSubscription', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
