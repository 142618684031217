const ClientDetails = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/ClientDetails.vue');
const ClientsList = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientsGrid.vue');
const ClientHistory = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientHistory.vue');
const ClientFavorite = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientFavorite.vue');
const ClientRental = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientRental.vue');
const ClientRates = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientRates.vue');
const ClientPayments = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientPayments.vue');


export const clientsRouting = [
    {path: '/clients', name: 'clients-list', component: ClientsList},
    {
        path: '/client/:id', component: ClientDetails, name: 'client-details',
    },
    {
        path: '/client/:id/history', component: ClientHistory, name: 'client-history'
    },
    {
        path: '/client/:id/favorite', component: ClientFavorite, name: 'client-favorite'
    },
    {
        path: '/client/:id/rental', component: ClientRental, name: 'client-rental'
    },
    {
        path: '/client/:id/rates', component: ClientRates, name: 'client-rates'
    },
    {
        path: '/client/:id/payments', component: ClientPayments, name: 'client-payments'
    }
]
