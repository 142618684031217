import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    lesson: null,
    masterClassName: null,
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    lesson: state => state.lesson,
    busy: state => state.busy,
    masterClassName: state => state.masterClassName,
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setLesson(state, response) {
        if (response == null) {
            state.lesson = null;
        } else {
            state.lesson = response.data;
        }
    },
    setMasterClassName(state, response) {
        if (response == null) {
            state.masterClassName = null;
        } else {
            state.masterClassName = response.data.name;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/master-class/${data.params.masterClassId}/lessons?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getLesson({commit}, data) {
        axios.get(`/lesson/${data}/details`)
            .then(function (response) {
                commit('setLesson', response);
            })
    },
    getMasterClassName({commit}, data) {
        axios.get(`/master-class/${data}/details`)
            .then(function (response) {
                commit('setMasterClassName', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
