const MasterClassDetails = () => import('./containers/MasterClassDetails.vue');
const MasterClassNew = () => import('./containers/MasterClassNew.vue');
const MasterClassList = () => import('./containers/MasterClassGrid.vue');


export const masterClassRouting = [
    {path: '/master-classes', name: 'master-classes-list', component: MasterClassList},
    {
        path: '/master-class/:id', component: MasterClassDetails, name: 'master-class-details'
    },
    {
        path: '/add-master-class', component: MasterClassNew, name: 'master-class-new'
    },
]
