<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        SoMusic
<!--        LOGO-->
<!--        <img src="~@/assets/logo-long.png" alt="">-->
      </div>

      <div class="card">
        <div class="card-body login-card-body">

          <b-alert v-if="error" variant="danger" show fade>{{ $t(error.toString()) }}</b-alert>

          <b-form @submit.stop.prevent="submit">
            <b-form-group id="example-input-group-1" label-for="example-input-1">
              <div class="input-group mb-3">
                <b-form-input
                    id="example-input-1"
                    name="example-input-1"
                    placeholder="Login"
                    v-model="$v.form.login.$model"
                    ref="login"
                    :state="validateState('login')"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
                <b-form-invalid-feedback
                    id="input-1-live-feedback"
                >This is a required field and must be email.
                </b-form-invalid-feedback>
              </div>

            </b-form-group>

            <b-form-group id="example-input-group-2" label-for="example-input-2">
              <div class="input-group mb-3">
                <b-form-input
                    placeholder="Password"
                    id="example-input-2"
                    name="example-input-2"
                    type="password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
                ></b-form-input>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
                <b-form-invalid-feedback
                    id="input-2-live-feedback"
                >This is a required field.
                </b-form-invalid-feedback>
              </div>


            </b-form-group>
            <div class="d-flex flex-row justify-content-end w-100">
              <b-button variant="primary" type="submit" :disabled="loading">
                <div v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  {{$t('state.loading')}}
                </div>
                <div v-else>
                  Login
                </div>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      configuration: false,
      login: null,
      error: null,
      password: null,
      form: {
        login: null,
        password: null
      }
    }
  },
  validations: {
    form: {
      login: {
        required
      },
      password: {
        required
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.getters["root/loading"];
    },
  },
  mounted() {
    this.$refs.login.focus();
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    submit() {
      this.error = null;
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }


      this.$store.dispatch('root/login', {login: this.form.login, password: this.form.password}).then(
          () => {
            this.$router.push({name: 'dashboard'});
          },
          error => {
            this.error = 'login.error.' + error.response.status;
            this.$store.commit('root/setLoader', false);
          }
      )

    }
  }
}
</script>
