import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    offer: null,
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    offer: state => state.offer,
    busy: state => state.busy,
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setOffer(state, response) {
        if (response == null) {
            state.offer = null;
        } else {
            state.offer = response.data;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/subscription-offer/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getOffer({commit}, data) {
        axios.get(`/subscription-offer/${data}/details`)
            .then(function (response) {
                commit('setOffer', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
