import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import dashboardStore from '../modules/dashboard/store';
import clientsStore from '../modules/clients/store/clientsStore';
import authorsStore from '../modules/authors/store/authorsStore';
import scoresStore from '../modules/scores/store/scoresStore';
import masterClassStore from '../modules/masterClass/store/masterClassStore';
import lessonsStore from "@/modules/lessons/store/lessonsStore";
import rentalStore from "@/modules/rental/store/rentalStore";
import paymentStore from "@/modules/payment/store/paymentStore";
import subscriptionOfferStore from "@/modules/subscriptionOffer/store/subscriptionOfferStore";
import ratesStore from "@/modules/rates/store/rateStore";
import cms from "@/modules/cms/store/index";
import statistics from "@/modules/statistics/store/statisticsStore";
import root from './root';

Vue.prototype.$http = axios;
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        root,
        dashboardStore,
        clientsStore,
        authorsStore,
        scoresStore,
        masterClassStore,
        lessonsStore,
        subscriptionOfferStore,
        ratesStore,
        rentalStore,
        paymentStore,
        cms,
        statistics
    },
    strict: true,
    plugins: debug ? [] : []
});
