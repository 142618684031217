import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    masterClass: null,
    filePath: null,
    authors: [],
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    masterClass: state => state.masterClass,
    busy: state => state.busy,
    authors: state => state.authors
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setMasterClass(state, response) {
        if (response == null) {
            state.masterClass = null;
        } else {
            state.masterClass = response.data;
        }
    },
    setAuthors(state, response) {
        if (response == null) {
            state.authors = null;
        } else {
            state.authors = response.data;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/master-class/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getMasterClass({commit}, data) {
        axios.get(`/master-class/${data}/details`)
            .then(function (response) {
                commit('setMasterClass', response);
            })
    },
    getAuthors({commit}) {
        axios.get(`/authors/dictionary`)
            .then(function (response) {
                commit('setAuthors', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
