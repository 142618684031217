const AuthorDetails = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/AuthorDetails.vue');
const AuthorNew = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/AuthorNew.vue');
const AuthorList = () => import(/* webpackChunkName: "PlayerList" */ './containers/AuthorGrid.vue');


export const authorsRouting = [
    {path: '/authors', name: 'authors-list', component: AuthorList},
    {
        path: '/author/:id', component: AuthorDetails, name: 'author-details'
    },
    {
        path: '/add-author', component: AuthorNew, name: 'author-new'
    },
]
