const LessonDetails = () => import('./containers/LessonDetails.vue');
const LessonNew = () => import('./containers/LessonNew.vue');
const LessonList = () => import('./containers/LessonGrid.vue');


export const lessonRouting = [
    {path: '/master-class/:masterClassId/lessons', name: 'lessons-list', component: LessonList},
    {
        path: '/master-class/:masterClassId/lesson/:id', component: LessonDetails, name: 'lesson-details'
    },
    {
        path: '/master-class/:masterClassId/lesson-new', component: LessonNew, name: 'lesson-new'
    },
]
