import axios from "axios";
import {createFilterQuery, i18n} from "@/main";

const clientApi = axios.create({
    baseURL: process.env.VUE_APP_CLIENT_API,
})

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    score: null,
    filePath: null,
    genres: [],
    authors: [],
    lessons: [],
    scoreTypes: [],
    lineUp: []
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    score: state => state.score,
    busy: state => state.busy,
    filePath: state => state.filePath,
    genres: state => state.genres,
    authors: state => state.authors,
    lessons: state => state.lessons,
    scoreTypes: state => state.scoreTypes,
    lineUp: state => state.lineUp
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setScore(state, response) {
        if (response == null) {
            state.score = null;
        } else {
            state.score = response.data;
            state.filePath = response.data.filePath
        }
    },
    setFilePath(state, filePath) {
        state.filePath = filePath;
    },
    setGenres(state, response) {
        if (response == null) {
            state.genres = [];
        } else {
            state.genres = response.data.map(el => {return {key : el, value: i18n.t('dictionary.genres.'+el)}})
        }
    },
    setScoreTypes(state, response) {
        if (response == null) {
            state.scoreTypes = [];
        } else {
            state.scoreTypes = response.data.map(el => {return {key : el, value: i18n.t('dictionary.score_types.'+el)}})
        }
    },
    setLineUp(state, response) {
        if (response == null) {
            state.lineUp = [];
        } else {
            state.lineUp = response.data.map(el => {return {key : el, value: i18n.t('dictionary.line_up.'+el)}})
        }
    },
    setAuthors(state, response) {
        if (response == null) {
            state.authors = null;
        } else {
            state.authors = response.data;
        }
    },
    setLessons(state, response) {
        if (response == null) {
            state.lessons = null;
        } else {
            state.lessons = response.data;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    loadItems({commit}, data) {
        axios.get(`/score/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getScore({commit}, data) {
        axios.get(`/score/${data}/details`)
            .then(function (response) {
                commit('setScore', response);
            })
    },
    changeFilePath (context, filePath) {
        context.commit('setFilePath', filePath)
    },
    getGenres({commit}) {
        clientApi.get(`/public/genres`)
            .then(function (response) {
                commit('setGenres', response);
            })
    },
    getScoreTypes({commit}) {
        clientApi.get(`/public/score-types`)
            .then(function (response) {
                commit('setScoreTypes', response);
            })
    },
    getLineUp({commit}) {
        clientApi.get(`/public/line-up`)
            .then(function (response) {
                commit('setLineUp', response);
            })
    },
    getAuthors({commit}) {
        axios.get(`/authors/dictionary`)
            .then(function (response) {
                commit('setAuthors', response);
            })
    },
    getLessons({commit}, ownerId = null) {
        let url = '/lessons/dictionary';

        if (ownerId) {
            url = url + '/' + ownerId;
        }
        
        axios.get(url)
            .then(function (response) {
                commit('setLessons', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
